.header {
  margin-top: 60px;
}

.headerTitles {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: 'Lora', serif;
  color: #444;
}

.headerTitleSm {
  position: absolute;
  top: 18%;
  font-size: 20px;
}

.headerTitleLg {
  position: absolute;
  top: 10%;
  font-size: 70px;
}

.headerImg {
  width: 100%;
  height: 404px;
  margin-top: -20px;
  object-fit: cover;
}
