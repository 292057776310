.top {
  width: 100%;
  height: 50px;
  background-color: white;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  display: flex;
  align-items: center;
  z-index: 999;
  font-family: 'Josefin Sans', sans-serif;
}

.topLeft,
.topRight {
  flex: 3 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.topIcon {
  font-size: 20px;
  margin-right: 10px;
  color: #444;
  cursor: pointer;
}

.topCenter {
  flex: 6 1;
  margin: 475px;
}

.topList {
  display: flex;
  justify-content: center;
  margin: 0;
  padding: 0;
  list-style: none;
}

.topListItem {
  margin-right: 20px;
  font-size: 18px;
  font-weight: 300;
  cursor: pointer;
}

.topListItem:hover {
  color: gray;
}

.topImg {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  object-fit: cover;
  margin-right: 15px;
  cursor: pointer;
}

.header {
  margin-top: 60px;
}

.headerTitles {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: 'Lora', serif;
  color: #444;
}

.headerTitleSm {
  position: absolute;
  top: 18%;
  font-size: 20px;
}

.headerTitleLg {
  position: absolute;
  top: 10%;
  font-size: 70px;
}

.headerImg {
  width: 100%;
  height: 404px;
  margin-top: -20px;
  object-fit: cover;
}

.post {
  width: 385px;
  margin: 0px 25px 40px 25px;
  display: flex;
  flex-direction: column;
}

.postImg {
  width: 385px;
  height: 280px;
  object-fit: cover;
  border-radius: 7px;
}

.postInfo {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.postCat {
  font-family: "Varela Round", Arial, Helvetica, sans-serif;
  font-weight: 400;
  font-size: 11px;
  color: #be9656;
  line-height: 19px;
  margin-top: 15px;
  margin-right: 10px;
  cursor: pointer;
}

.postTitle {
  font-family: "Josefin Sans", Arial, Helvetica, sans-serif;
  font-size: 24px;
  font-weight: 900;
  margin-top: 15px;
  cursor: pointer;
}

.postDate {
  font-family: "Lora", serif;
  font-style: italic;
  font-size: 13px;
  font-weight: 400;
  color: #999999;
  margin-top: 15px;
}

.postDesc {
  font-family: "Varela Round", Arial, Helvetica, sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #444444;
  margin-top: 15px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
}

.posts{
    flex: 9 1;
    display: flex;
    flex-wrap: wrap;
    margin: 20px;
}


.sidebar {
  flex: 3 1;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  margin: 20px;
  padding-bottom: 30px;
  background-color: #fdfbfb;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.sidebarImage {
  width: 300px;
  height: 450px;
}

.sidebarItem {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.sidebarTitle {
  margin: 10px;
  padding: 5px;
  width: 80%;
  border-top: solid 1px #a7a4a4;
  border-bottom: solid 1px #a7a4a4;
  text-align: center;
  font-family: 'Varela Round', sans-serif;
  font-size: 12px;
  line-height: 19px;
  color: #222222;
  font-weight: 600;
}

.sidebarItem > img {
  margin-top: 15px;
  width: 250px;
  height: 250px;
}

.sidebarItem > p {
  padding: 30px;
}

.sidebarList {
  list-style-type: none;
  margin-bottom: 30px;
}

.sidebarListItem {
  display: inline-block;
  width: 50%;
  margin-top: 15px;
  cursor: pointer;
}

.sidebarSocial {
  margin-top: 15px;
  width: 250px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.sidebarIcon {
  font-size: 16px;
  margin-left: 10px;
}

.home {
  display: flex;
}

.singlePost {
  flex: 9 1;
}

.singlePostWrapper {
  padding: 20px;
  padding-right: 0;
}

.singlePostImg {
  width: 100%;
  height: 300px;
  border-radius: 5px;
  object-fit: cover;
}

.singlePostTitle {
  text-align: center;
  margin: 10px;
  font-size: 28px;
  font-family: "Lora", sans-serif;
}

.singlePostEdit {
  float: right;
  font-size: 16px;
}

.singlePostIcon {
  margin-left: 10px;
  cursor: pointer;
}

.singlePostIcon:first-child {
  color: teal;
}
.singlePostIcon:last-child {
  color: tomato;
}

.singlePostInfo {
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
  font-size: 16px;
  color: #be9656;
  font-family: "Varela Round", Arial, Helvetica, sans-serif;
}

.singlePostAuthor{
  margin-left: 5px;
}

.singlePostDesc {
  color: #666;
  font-size: 18px;
  line-height: 25px;
}

.singlePostDesc::first-letter {
  margin-left: 20px;
  font-size: 30px;
  font-weight: 600;
}

.single{
    display: flex;
}
